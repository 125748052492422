import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import 'leaflet-polylinedecorator';
import moment from 'moment-timezone';
import { LOCALE_FORMAT, SESSION_TYPE } from '../../../../constants';
import { I18nContext } from '../../../../contexts/I18nProvider';
import { useTrackColoration } from '../../../../effects/TrackEffects';
import { I18n } from '../../../../i18n';
import { L as Leaflet } from '../../../../utils/LeafletOverrides';
import { getEquipmentIcon } from '../../../../utils/equipments';
import marker_green from './marker_green.png';
import marker_orange from './marker_orange.png';

export function useDrawTracksOnMap(
  map,
  tracks,
  events,
  buildStyle,
  tracksWithoutFlags = false,
  withEnd = true,
  withEndIcons = null,
) {
  const [tracksMap, setTracksMap] = useState({});
  const [decoratorsMap, setDecoratorsMap] = useState({});
  const toggleItemsOnZoom = useCallback(
    (layerGroup, zoom) => {
      if (map._zoom > zoom && !map.hasLayer(layerGroup)) {
        layerGroup.addTo(map);
      }
      if (map._zoom <= zoom && map.hasLayer(layerGroup)) {
        layerGroup.removeFrom(map);
      }
    },
    [map],
  );

  useEffect(() => {
    if (!map || !tracks || !tracks.type || tracks.features === null || tracks.features.length === 0) return () => {};
    let decorators = [],
      flags = [],
      trackLayersMap = {},
      decoratorLayersMap = {};
    const layer = Leaflet.geoJSON(tracks, {
      style: buildStyle,
      pane: 'shadowPane',
      onEachFeature: function (feature, layer) {
        const [localTrackLayersMap, localDecoratorLayersMap] = handleArrowsDecoratorsAndEvents({
          feature,
          layer,
          decorators,
          buildStyle,
          events,
          wholeTracksMap: trackLayersMap,
          wholeDecoratorsMap: decoratorLayersMap,
        });
        trackLayersMap = {
          ...trackLayersMap,
          ...localTrackLayersMap,
        };
        decoratorLayersMap = {
          ...decoratorLayersMap,
          ...localDecoratorLayersMap,
        };
        if (!tracksWithoutFlags) {
          handleStartAndEndDecorators({
            layer,
            flags,
            withEnd,
            withEndIcons,
            fromDate: feature?.properties?.from_date,
            toDate: feature?.properties?.to_date,
          });
        }
      },
    });
    const decoratorsLayerGroup = Leaflet.layerGroup(decorators);
    const flagsLayerGroup = Leaflet.layerGroup(flags);
    // always display start/end flags if we have only one track
    if ((map._zoom > 17 || tracks?.features?.length === 1) && decorators?.length) {
      decoratorsLayerGroup.addTo(map);
      flagsLayerGroup.addTo(map);
    }
    setTracksMap(trackLayersMap);
    setDecoratorsMap(decoratorLayersMap);
    layer.addTo(map);
    const listener = () => toggleItemsOnZoom(decoratorsLayerGroup, 17);
    const flagsListener = () => toggleItemsOnZoom(flagsLayerGroup, 17);
    if (tracks?.features?.length > 1) {
      map.on('zoomend', listener);
      map.on('zoomend', flagsListener);
    }
    return () => {
      if (tracks?.features?.length > 1) {
        map.off('zoomend', listener);
        map.off('zoomend', flagsListener);
      }
      decoratorsLayerGroup.removeFrom(map);
      flagsLayerGroup.removeFrom(map);
      layer.removeFrom(map);
    };
  }, [map, tracks, events, buildStyle, toggleItemsOnZoom, withEnd, tracksWithoutFlags, withEndIcons]);
  return [tracksMap, decoratorsMap];
}

export function useTrackCoords(tracks = {}) {
  const computed = useRef([]);
  return useMemo(() => {
    if (
      !tracks ||
      (!tracks.bbox && !tracks.features) ||
      (tracks.features && computed.current.length === 2 * tracks.features.length)
    ) {
      //if we have already computed a bounding box for an array of tracks having the same length we skip (the factor two is due to the concat of two coordinates)
      //the optimization can be remove as soon as the array of tracks reference persist across renders
      return computed.current;
    }

    let p = [];
    if (tracks.features) {
      p =
        tracks.features &&
        tracks.features
          .filter((track) => track.geometry && track.geometry.bbox)
          .reduce((value, track) => {
            if (
              track.geometry.bbox[1] === 0 &&
              track.geometry.bbox[0] === 0 &&
              track.geometry.bbox[3] === 0 &&
              track.geometry.bbox[2] === 0
            ) {
              return value;
            }
            return value.concat([
              [track.geometry.bbox[1], track.geometry.bbox[0]],
              [track.geometry.bbox[3], track.geometry.bbox[2]],
            ]);
          }, []);
    }
    if (tracks.bbox) {
      p = [
        [tracks.bbox[1], tracks.bbox[0]],
        [tracks.bbox[3], tracks.bbox[2]],
      ];
    }
    computed.current = p;
    return p;
  }, [tracks]);
}

export function useTracksDefaultStyle() {
  return useCallback(() => {
    return {
      weight: 2,
      strokeColor: '#B5E936',
      color: '#B5E936',
      pane: 'shadowPane',
    };
  }, []);
}

export function useTracksHighlightStyle() {
  const style = useCallback((feature) => {
    const { sessionType } = (feature && feature.properties) || {};
    let theColor = '#B5E936';
    let thePane = 'shadowPane';
    let theWeight = 2;
    if (sessionType === SESSION_TYPE.EQUIPMENT_SESSION) {
      theColor = '#2AC491';
    }
    return {
      strokeColor: theColor,
      color: theColor,
      pane: thePane,
      weight: theWeight,
    };
  }, []);
  return [style];
}

export function useTracksOverrideStyle() {
  const getTrackColoration = useTrackColoration();

  const featureStyle = useCallback(
    (feature) => {
      if (!feature?.properties) return;
      const trackProps = feature.properties;
      const trackColoration = getTrackColoration(trackProps);
      if (trackColoration) {
        return {
          strokeColor: trackColoration,
          color: trackColoration,
          weight: 2,
          pane: 'shadowPane',
        };
      }
    },
    [getTrackColoration],
  );

  return useMemo(() => [featureStyle], [featureStyle]);
}

export function useOverTracks({ overTrackID, tracksMap, decoratorsMap, trackStyles, overStyle }) {
  const wasOver = useRef(null);

  useEffect(() => {
    if (wasOver.current && tracksMap[wasOver.current]) {
      tracksMap[wasOver.current].forEach((wot) => {
        if (wot.feature.properties.sessionType === SESSION_TYPE.EQUIPMENT_SESSION) {
          wot.bringToBack();
        }
        wot.setStyle(trackStyles(wot.feature));
      });
      decoratorsMap[wasOver.current].forEach((wod) => {
        wod.bringToBack();
        wod.setStyle(trackStyles(wod.feature));
      });
    }
    wasOver.current = overTrackID;
    if (overTrackID && tracksMap[overTrackID]) {
      tracksMap[overTrackID].forEach((tl) => {
        tl.bringToFront();
        tl.setStyle(overStyle);
      });
      decoratorsMap[overTrackID].forEach((dl) => {
        dl.bringToFront();
        dl.setStyle(overStyle);
      });
    }
  }, [overTrackID, tracksMap, decoratorsMap, trackStyles, overStyle]);
}

const handleArrowsDecoratorsAndEvents = ({
  feature,
  layer,
  decorators,
  buildStyle,
  events,
  wholeTracksMap,
  wholeDecoratorsMap,
}) => {
  const builtStyleColor = buildStyle && buildStyle(feature).strokeColor;
  const localDecorators = [];
  if (!layer.getLatLngs && layer.getLayers && layer.getLayers().length === 1) {
    layer = layer.getLayers()[0];
  }
  if (layer.getLatLngs) {
    const decorator = Leaflet.polylineDecorator(layer.getLatLngs(), {
      patterns: [
        {
          offset: 10,
          repeat: 200,
          symbol: Leaflet.Symbol.arrowHead({
            pixelSize: 10,
            pathOptions: { fillOpacity: 1, weight: 0, strokeColor: builtStyleColor, color: builtStyleColor },
          }),
        },
      ],
    });
    decorators.push(decorator);
    localDecorators.push(decorator);
    Object.keys(events || {}).forEach((event) => {
      layer.on(event, (e) => events[event](feature, layer, decorator, e));
    });
    const possibleIdKeys = ['parcel_session_id', 'equipment_session_id', 'device_session_id'];
    let trackLayersMap = {},
      decoratorLayersMap = {};
    possibleIdKeys.forEach((key) => {
      if (feature?.properties && feature?.properties[key]) {
        trackLayersMap = {
          ...trackLayersMap,
          [feature.properties[key]]: ((wholeTracksMap || {})[feature.properties[key]] || []).concat(layer),
        };
        decoratorLayersMap = {
          ...decoratorLayersMap,
          [feature.properties[key]]: ((wholeDecoratorsMap || [])[feature.properties[key]] || []).concat(
            localDecorators,
          ),
        };
      }
    });
    return [trackLayersMap, decoratorLayersMap];
  }
  return [{}, {}];
};

const handleStartAndEndDecorators = ({ layer, flags, withEnd = true, withEndIcons = null, fromDate, toDate }) => {
  if (layer.getLatLngs) {
    const startMarker = Leaflet.Symbol.marker({
      markerOptions: {
        icon: Leaflet.icon({
          iconUrl: marker_orange,
          iconAnchor: [7.5, 20],
          iconSize: [15, 20],
        }),
      },
    });
    const startFlag = Leaflet.polylineDecorator(layer.getLatLngs(), {
      patterns: [
        {
          offset: '0%',
          repeat: '0',
          symbol: startMarker,
        },
      ],
    });
    startFlag.bindTooltip(`${I18n.t('Commons.start')} : ${moment(fromDate).format('LLL')}`, { direction: 'bottom' });
    flags.push(startFlag);
    let endFlag;
    // if we define `withEndIcons`, it means we want to display them instead of the default end flag
    // and if the `withEnd` is not defined, it means the track is not over, so we display the toDate as the last update date
    if (withEndIcons?.length) {
      withEndIcons.forEach((icon) => {
        endFlag = Leaflet.polylineDecorator(layer.getLatLngs(), {
          patterns: [
            {
              offset: '100%',
              repeat: '0',
              symbol: Leaflet.Symbol.marker({
                markerOptions: {
                  icon,
                },
              }),
            },
          ],
        });
        if (withEnd) {
          endFlag.bindTooltip(`${I18n.t('Commons.end')} : ${moment(toDate).format('LLL')}`, { direction: 'bottom' });
        } else {
          endFlag.bindTooltip(`${I18n.t('Commons.updated_at')} : ${moment(toDate).format('LLL')}`, {
            direction: 'bottom',
          });
        }
        flags.push(endFlag);
      });
    } else {
      if (withEnd) {
        endFlag = Leaflet.polylineDecorator(layer.getLatLngs(), {
          patterns: [
            {
              offset: '100%',
              repeat: '0',
              symbol: Leaflet.Symbol.marker({
                markerOptions: {
                  icon: Leaflet.icon({
                    iconUrl: marker_green,
                    iconAnchor: [7.5, 20],
                    iconSize: [15, 20],
                  }),
                },
              }),
            },
          ],
        });
        endFlag.bindTooltip(`${I18n.t('Commons.end')} : ${moment(toDate).format('LLL')}`, { direction: 'bottom' });
        flags.push(endFlag);
      }
    }
  }
};

export function useTrackTooltipLabels() {
  const { t } = useContext(I18nContext);
  return useMemo(
    () => ({
      start: t('Commons.start'),
      end: t('Commons.end'),
      see: t('Commons.see'),
      active: t('Commons.active'),
    }),
    [t],
  );
}

export function trackTooltipData(track = {}, history, t) {
  const { properties } = track;
  const Icon = properties?.equipment_model_type
    ? getEquipmentIcon(properties?.equipment_model_type)
    : getEquipmentIcon('misc');

  // get current parcel URL
  const parcelPage = history.location.pathname.match(/\/parcels\/\d+/)?.at(0);

  const action = properties.equipment_session_id
    ? () => history.push(`/equipments/${properties.equipment_instance_id}/sessions/${properties.equipment_session_id}`)
    : properties.equipment_id
      ? () => history.push(`/equipments/${properties.equipment_id}`)
      : properties.parcel_session_id
        ? () => history.push(`${parcelPage}/sessions/${properties.parcel_session_id}`)
        : null;

  const type = t(`WorkType.${properties.equipment_model_type}`);

  return {
    Icon: Icon,
    equipmentName: properties.equipment_name,
    worksiteType: type,
    fromDate: moment(properties.fromDate || properties?.from_date).format(LOCALE_FORMAT),
    toDate: moment(properties.toDate || properties?.to_date).format(LOCALE_FORMAT),
    action,
  };
}
